import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { createSelector } from 'reselect';

import { getListFromIds } from 'store/selectors';
import {
  clearProfileFilters,
  profileListThunk,
  updateProfileFilter,
} from 'store/actions/accounts/profiles';
import { affiliationListThunk } from 'store/actions/accounts/affiliations';
import { userGroupListThunk } from 'store/actions/accounts/groups';
import { publicProfilePropTypes } from 'helpers/proptypes';
import { Alert } from 'components';
import { Pagination } from 'components/shared';
import ProfileCards from './ProfileCards';
import { EmployeeTypeFilter, RadioFilter } from './filters';

class PublicProfilesListPage extends React.Component {
  static propTypes = {
    affiliationListThunk: PropTypes.func.isRequired,
    profileListThunk: PropTypes.func.isRequired,
    userGroupListThunk: PropTypes.func.isRequired,
    updateProfileFilter: PropTypes.func.isRequired,
    clearProfileFilters: PropTypes.func.isRequired,
    affiliations: PropTypes.arrayOf(PropTypes.array).isRequired,
    managers: PropTypes.arrayOf(PropTypes.array).isRequired,
    profiles: PropTypes.arrayOf(publicProfilePropTypes),
    filters: PropTypes.object,
    count: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    next: PropTypes.string,
    previous: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
  };

  debounceProfileListThunk = debounce(this.props.profileListThunk, 200, {
    maxWait: 1000,
    trailing: true,
  });

  async componentDidMount() {
    this.props.profileListThunk(this.props.filters);
    this.props.userGroupListThunk('managers');
    this.props.affiliationListThunk();
  }

  clearFilters = () => {
    this.props.clearProfileFilters().then(this.props.profileListThunk);
  };

  searchChanged = (e) => {
    const search = e.target.value;
    this.props.updateProfileFilter({ name: 'user', value: search });
    this.debounceProfileListThunk({ user: search });
  };

  employeeTypeChanged = (filters) => {
    this.props.updateProfileFilter({ name: 'employee_type', value: filters });
    this.props.profileListThunk({ employee_type: filters.join(',') });
  };

  managerChanged = (id) => {
    this.props.updateProfileFilter({ name: 'manager', value: id });
    this.props.profileListThunk({ manager: id });
  };

  affiliationChanged = (id) => {
    this.props.updateProfileFilter({ name: 'affiliation', value: id });
    this.props.profileListThunk({ affiliation: id });
  };

  paginationClick = (pageQueryParam) => {
    this.props.profileListThunk(pageQueryParam);
  };

  render() {
    if (this.props.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Error, could not retrieve results.
        </Alert>
      );
    }

    return (
      <React.Fragment>
        <div className="col-xs-12 col-md-offset-3 col-md-9">
          <h1 className="page-header center">DAS Profiles</h1>
        </div>
        <div className="col-md-3 survey">
          <div className="question-block">
            <p className="text-size-lg">
              <strong>Filters</strong>
              <button
                type="button"
                onClick={this.clearFilters}
                className="btn btn-sm btn-default pull-right"
                style={{
                  marginTop: '3px',
                  outline: 'none',
                  backgroundColor: '#fff',
                  boxShadow: 'none',
                }}
              >
                <i className="fa fa-refresh icon-space-r" />
                Reset
              </button>
            </p>
            <div className="form-group">
              <EmployeeTypeFilter
                value={this.props.filters.employee_type}
                onChange={this.employeeTypeChanged}
              />
              <RadioFilter
                title="Manager"
                name="profile-manager-filter"
                value={this.props.filters.manager}
                onChange={this.managerChanged}
                options={this.props.managers}
                count={this.props.count}
                isLoading={this.props.isLoading}
              />
              <RadioFilter
                title="Affiliation"
                name="profile-affiliation-filter"
                value={this.props.filters.affiliation}
                onChange={this.affiliationChanged}
                options={this.props.affiliations}
                count={this.props.count}
                isLoading={this.props.isLoading}
              />
              <Pagination
                count={this.props.count}
                nextUrl={this.props.next}
                previousUrl={this.props.previous}
                pageSize={this.props.pageSize}
                onClick={this.paginationClick}
              />
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="form-group">
            <div className="input-group">
              <input
                type="text"
                id="search"
                className="form-control input-lg search-input"
                placeholder="Search.."
                name="query"
                onChange={this.searchChanged}
                value={this.props.filters.search}
              />
              <label className="sr-only" htmlFor="search">
                Search Profiles
              </label>
              <div className="input-group-addon">
                <i className="fa fa-search" />
              </div>
            </div>
          </div>
          <div className="row">
            <ProfileCards
              isLoading={this.props.isLoading}
              hasErrored={this.props.hasErrored}
              profiles={this.props.profiles}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const getManagers = (state) => state.groups.managers;
const selectManagerOptions = createSelector(
  [getManagers],
  (managers) => {
    if (!managers) return [];
    return managers.map((manager) => [manager.pk + '', manager.name]);
  }
);

const affiliationSort = (a, b) => {
  const nameA = a[1].toLowerCase();
  const nameB = b[1].toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

const getAffiliations = (state) => state.affiliations.list;
const selectAffiliationOptions = createSelector(
  [getAffiliations],
  (affiliations) => {
    if (!affiliations) return [];
    return affiliations
      .map((affiliation) => [affiliation.id + '', affiliation.short_name || affiliation.name])
      .sort(affiliationSort);
  }
);

const mapStateToProps = (state) => {
  return {
    isLoading: state.profiles.keys.isLoading || state.groups.isLoading,
    hasErrored: state.profiles.keys.hasErrored || state.groups.hasErrored,
    managers: selectManagerOptions(state),
    affiliations: selectAffiliationOptions(state),
    profiles: getListFromIds(state.profiles),
    filters: state.profiles.keys.filters,
    count: state.profiles.keys.count,
    next: state.profiles.keys.next,
    previous: state.profiles.keys.previous,
    pageSize: state.profiles.keys.page_size,
  };
};

export default connect(
  mapStateToProps,
  {
    affiliationListThunk,
    clearProfileFilters,
    profileListThunk,
    userGroupListThunk,
    updateProfileFilter,
  }
)(PublicProfilesListPage);
