import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { RadioGroup, Radio } from 'react-radio-group';

class RadioFilter extends React.PureComponent {
  state = {
    hidden: !this.props.value,
  };

  toggleHidden = () => this.setState((prevState) => ({ hidden: !prevState.hidden }));

  render() {
    const { isLoading, ...props } = this.props;
    const { hidden } = this.state;
    const iconClass = cx('fa icon-space-r', { 'fa-plus': hidden, 'fa-minus': !hidden });
    const options = [['', 'Any'], ...props.options];
    return (
      <React.Fragment>
        <div className="mt1 cursor-pointer" onClick={this.toggleHidden}>
          <strong>
            <i className={iconClass} />
            {props.title}
          </strong>
        </div>
        {!hidden && (
          <RadioGroup {...props} name={props.name} selectedValue={props.value}>
            {options.map((option) => {
              return (
                <div className="radio" key={option[0]}>
                  <label>
                    <Radio value={option[0]} />
                    {option[1]}
                    {option[0] === props.value && !isLoading && ` (${props.count})`}
                  </label>
                </div>
              );
            })}
          </RadioGroup>
        )}
      </React.Fragment>
    );
  }
}

RadioFilter.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.array),
  onChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RadioFilter;
