import React from 'react';
import { Link } from 'react-router-dom';
import { publicProfilePropTypes } from 'helpers/proptypes';

const ProfileCard = ({ profile }) => {
  return (
    <Link
      to={window.URLS['accounts:public_profile_detail'](profile.slug)}
      className="employee-card employee-card--clickable"
    >
      <div className="employee-card employee-card--hover">
        <div className="employee-card--header" />
        <div className="employee-card--avatar">
          <img
            src={profile.avatar || 'https://www.gravatar.com/avatar/?d=mm&s=115'}
            alt={`${profile.name}'s Photo`}
          />
        </div>
        <div className="employee-card--info">
          <div className="employee-card--title">{profile.name}</div>
          <div className="employee-card--desc-container">
            {profile.title && (
              <div className="employee-card--desc">
                <span className="fa fa-briefcase fa-fw icon-space-l icon-space-r" />
                {profile.title}
              </div>
            )}
            {profile.phone && (
              <div className="employee-card--desc">
                <span className="fa fa-phone fa-fw icon-space-l icon-space-r" />
                {profile.phone}
              </div>
            )}
            {profile.office && (
              <div className="employee-card--desc">
                <span className="fa fa-map-marker fa-fw icon-space-l icon-space-r" />
                {profile.office}
              </div>
            )}
            {profile.user.email && (
              <div className="employee-card--desc">
                <span className="fa fa-envelope fa-fw icon-space-l icon-space-r" />
                {profile.user.email}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

ProfileCard.propTypes = {
  profile: publicProfilePropTypes.isRequired,
};

export default ProfileCard;
