import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Checkbox, CheckboxGroup } from 'react-checkbox-group';
import { EventLabels } from 'helpers/dictionaries';

const { EMPLOYEE_TYPES } = EventLabels;

class EmployeeTypeFilter extends React.PureComponent {
  state = {
    hidden: !this.props.value.length,
  };

  toggleHidden = () => this.setState((prevState) => ({ hidden: !prevState.hidden }));

  render() {
    const { hidden } = this.state;
    const iconClass = cx('fa icon-space-r', { 'fa-plus': hidden, 'fa-minus': !hidden });
    return (
      <React.Fragment>
        <div className="mt1 cursor-pointer" onClick={this.toggleHidden}>
          <strong>
            <i className={iconClass} />
            Employee Type
          </strong>
        </div>
        {!hidden && (
          <CheckboxGroup
            {...this.props}
            value={this.props.value}
            name="employee-type-filter"
            onChange={this.props.onChange}
            checkboxDepth={3}
          >
            {Object.entries(EMPLOYEE_TYPES).map((entry) => (
              <div className="checkbox" key={entry[0]}>
                <label>
                  <Checkbox value={entry[0]} />
                  {entry[1]}
                </label>
              </div>
            ))}
          </CheckboxGroup>
        )}
      </React.Fragment>
    );
  }
}

EmployeeTypeFilter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func.isRequired,
};

export default EmployeeTypeFilter;
