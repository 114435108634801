import React from 'react';
import PropTypes from 'prop-types';

import { publicProfilePropTypes } from 'helpers/proptypes';
import { Alert, Loading } from 'components';
import ProfileCard from './ProfileCard';

const ProfileCards = ({ isLoading, hasErrored, profiles }) => {
  if (hasErrored) {
    return (
      <div className="col-xs-12">
        <Alert type="danger" icon="fa-exclamation icon-space-r">
          Error, could not retrieve results.
        </Alert>
      </div>
    );
  }
  if (isLoading && !profiles.length) {
    return (
      <div className="col-xs-12">
        <Loading />
      </div>
    );
  }

  if (!profiles.length) {
    return (
      <div className="col-xs-12">
        <Alert type="info" icon="fa-info-circle icon-space-r">
          No results found, please try removing some filters.
        </Alert>
      </div>
    );
  }

  const profileCards = profiles.map((profile) => (
    <div key={profile.pk} className="col-sm-6 col-lg-4">
      <ProfileCard profile={profile} />
    </div>
  ));

  if (profiles.length === 1) {
    return <div className="flex-row flex-row--center">{profileCards}</div>;
  }

  return <React.Fragment>{profileCards}</React.Fragment>;
};

ProfileCards.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasErrored: PropTypes.bool.isRequired,
  profiles: PropTypes.arrayOf(publicProfilePropTypes),
};

export default ProfileCards;
